'use strict';

import $ from 'jquery';
import { debounce, isBreakpointActive } from './helpers';

window.app = window.app || {};
window.app.variables = window.app.variables || {};

const $document = $(document);
const $window = $(window);
const $html = $('html');
const $body = $('body');

windowResize();
collapseSearch();

$window.on('resize', debounce(windowResize, 250, false));

$window.on('load', jsDone);

setTimeout(jsDone, 4000);

$('.js-notice-close').on('click', function (e) {

    e.preventDefault();

    const $notice = $(this).closest('.notice');

    $notice.slideToggle(250, () => {
        $notice.remove();
    });
});

function windowResize() {

    const isFlyoutActive = isBreakpointActive('flyout');
    const windowWidth = $window.width();
    const windowHeight = $window.height();

    if (!isFlyoutActive) {
        $body.removeClass('flyout-active');
    }

    $.extend(window.app.variables, {
        isFlyoutActive,
        windowWidth,
        windowHeight
    });
}

function collapseSearch() {

    var $collapseTrigger = $('.js-search-trigger');
    var $form = $('.js-search-form');

    // register clicks and toggle classes
    $collapseTrigger.on('click', function (e) {

        e.preventDefault();

        var $container = $(this).closest('.js-search-container');
        var $inputEl = $container.find('.js-search-input');

        if ($form.hasClass('active')) {
            $form.removeClass('active');
            $body.removeClass('search-active');
            $('.js-search-container').removeClass('active');
        } else {
            $inputEl.addClass('focus');
            $form.addClass('active');
            $body.addClass('search-active');
            $('.js-search-container').addClass('active');
            var $labelId = $form.find('.js-search-focus').attr('id');
            $('#' + $labelId).focus();
        }
    });

    $document.on('click', '.search__close, .overlay', function (event) {

        $body.removeClass('search-active');
        $form.removeClass('active');
        $('.js-search-container').removeClass('active');
        // $collapseTrigger.find('i').toggleClass('icon--clear icon--search');

    });

    $document.keyup( function (e) {

        if (e.keyCode === 27) {
            if ($form.hasClass('active')) {
                $form.removeClass('active');
                $body.removeClass('search-active');
                $('.js-search-container').removeClass('active');
                // $collapseTrigger.find('i').toggleClass('icon--clear icon--search');
            }
        }
    });

}

function jsDone() {
    $html.addClass('js-done');
}
