'use strict';

require('slick-carousel');

import $ from 'jquery';

slick();

function slick() {
    $('.js-slider').slick({
        fade: true,
    });
}
