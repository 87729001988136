'use strict';

import $ from 'jquery';
import Parsley from 'parsleyjs';
import { extend } from './helpers';

require('../parsley/nl');

const $document = $(document);
const $window = $(window);
const $html = $('html');
const $body = $('body');
const $forms = $('form');

window.Parsley = Parsley;

const $name = $('[data-dependant-name]').attr('data-dependant-name');
if ($name) {
    formToggle();
}

function errorsContainer(ParsleyField) {

    const $formItem = ParsleyField.$element.closest('.form__item');
    var $formGroup = $formItem.closest('.form__group');

    if ($formGroup.length) {
        return $formGroup;
    } else {
        return $formItem;
    }
}

extend(window.Parsley.options, {

    classHandler: errorsContainer,

    errorClass: 'has-error',

    errorsContainer: errorsContainer,

    errorsWrapper: '<ul class="form__errors"></ul>',

    excluded: 'input:not(:visible), input.novalidate, select:not(:visible)'
});

function formToggle() {
    const name = $('[data-dependant-name]').attr('data-dependant-name');
    const $inputType = $(`[name="${name}"]`);
    const $container = $('.js-input-specify');
    const $values = $('[data-dependant-name]').attr('data-dependant-value').split('|');

    $inputType.on('change', e => {
        $('[data-dependant-name] input, [data-dependant-name] textarea, [data-dependant-name] select').each((i, input) => {
            const $input = $(input);

            // Checkbox
            if ($inputType[0].className === 'form__checkbox js-form-select') {
                var value = $inputType[0].checked;
            } // Radio
            else if ($inputType[0].className === 'form__radio js-form-select js-shipping-select') {
                var $selectedOption = $('.js-form-select:checked');
                var value = $selectedOption.attr('value');
            } // Select
            else {
                const $selectedOption = $(e.target).find('option:selected');
                var value = $selectedOption.attr('value');
            }
            const isVisible = ($values.indexOf("" + value) > -1);
            $container.toggle(isVisible);
            $input.prop('disabled', !isVisible);

            // Inputvelden leegmaken indien ze hidden zijn (zodat ze ook niet in de backend komen)
            /*if (!isVisible) {
                $input.val("");
            }*/
        });
    }).trigger('change');
}

window.Parsley.setLocale(document.documentElement.lang);

window.Parsley.on('field:error', function () {
    const $errorElement = window.Parsley.options.classHandler(this);
    $errorElement.closest('.form').addClass('has-errors');
    $errorElement.find('.form__errors-server').remove();
});

$forms.on('click', 'button[type=submit]', submit);

function submit() {

    const $form = $(this.form);

    if ($form.data('is-submitted')) {
        return false;
    }

    if ($form.parsley().isValid()) {
        $form.data('is-submitted', true).addClass('is-submitted');
    }
}
