'use strict';

import './components/common';
import './components/form';
import './components/flyout';
import './components/slider';
import './components/responsiveBackground';
// import './components/google';

//  Icon font generation - do not remove
import '../icons/iconfont.font';
